/* @tailwind base; */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.navDropdown {
  display: none;
}
.navDropdownButton:hover > .navDropdown {
  display: flex;
}
.hide {
  display: none;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(128, 128, 128, 0.125);
}

*::-webkit-scrollbar-thumb {
  background-color: #5eca2a;
  /* #ff7a00; Set color of scrollbar thumb */
  border-radius: 100px;
}

@layer components {
  /* Buttons */
  .btnPrimary {
    @apply px-4 py-2 bg-secondary text-sm whitespace-nowrap text-center flex items-center justify-center gap-1 text-white capitalize shadow-black/30 rounded-md cursor-pointer transition-all;
  }
  .btnPrimary:hover {
    @apply opacity-90;
  }
  .btnPrimary:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  .btnSecondary {
    @apply px-4 py-2 text-sm bg-secondary/80 whitespace-nowrap font-medium text-center text-white capitalize shadow-md dark:shadow-white/5 shadow-gray-300 rounded cursor-pointer;
  }
  .btnSecondary:active:not(:disabled) {
    @apply scale-105 transition-all;
  }
  .btnSecondary:hover:not(:disabled) {
    @apply opacity-90;
  }
  .btnSecondary:disabled {
    @apply bg-[#aaaaaa];
  }
  .btnWhite {
    @apply font-medium text-secondary cursor-pointer py-2 px-4 shadow-md bg-white text-center rounded-full transition-all;
  }
  .btnTable {
    @apply rounded shadow shadow-black/30 h-8 flex items-center justify-center py-2 px-3;
  }

  /* Dark Mode */
  .light {
    @apply bg-gray-50 text-black;
  }
  .dark {
    @apply bg-dark text-white;
  }

  /* Heading */
  .elementHeading1 {
    @apply text-3xl lg:text-4xl font-bold;
  }
  .elementHeading2 {
    @apply text-2xl lg:text-3xl font-bold;
  }
  .elementHeading3 {
    @apply text-xl lg:text-2xl font-bold;
  }
  .elementHeading4 {
    @apply text-lg lg:text-xl font-bold;
  }
  .elementHeading5 {
    @apply lg:text-lg font-bold;
  }

  /* Links */
  .pageLink {
    @apply p-2 rounded-lg flex items-center w-full outline-none whitespace-nowrap transition-all;
  }
  .pageLink:hover {
    @apply no-underline bg-gray-100/10;
  }

  /* Table */
  .tableHead {
    @apply px-3 py-2 text-sm text-left z-50 whitespace-nowrap font-semibold text-gray-900 dark:text-white/80 uppercase tracking-wider;
  }
  .tableBody {
    @apply bg-white divide-y divide-gray-200 h-full overflow-y-scroll;
  }

  /* Input Fields */
  .inputLabel {
    @apply block text-gray-900 text-sm font-semibold capitalize;
  }
  .inputField {
    @apply w-full px-4 py-2 border rounded outline-none;
  }
  .inputField:focus {
    @apply ring-2 ring-yellow-500;
  }

  /* Cards */
  .card {
    @apply dark:bg-blue bg-white shadow-xl dark:shadow-2xl shadow-gray-200 dark:shadow-black/30 border dark:border-dark p-5 lg:p-6 rounded-lg;
  }

  .pagination {
    @apply disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue/10 transition-all dark:hover:bg-white/20 rounded-full p-1;
  }
}

.table-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.column-header {
  padding: 8px 16px;
  font-weight: bold;
}

.table-content {
  margin-top: 36px; /* Adjust this value to match the height of the sticky header */
}
.css-12u49z-MuiDataGrid-root {
  border: none !important;
}
#backlink_content a {
  color: #ff7a00;
}
#backlink_content a:hover {
  color: #ff7a00;
  text-decoration: underline;
}
.MuiDataGrid-root {
  border-width: 0 !important;
  border-color: transparent !important;
  border-style: none !important;
}
.css-aop0yh {
  border-width: 0 !important;
  border-color: transparent !important;
  border-style: none !important;
}
.animate-blink {
  animation: blink 2s infinite;
}
.custom-date-input::-webkit-calendar-picker-indicator {
  height: 1.3em;
  width: 1.3em;
}
.custom-date-input::-webkit-datetime-edit {
  font-size: 1em;
}
.dropdown-panel-batches .p-dropdown-item {
  @apply border-b border-gray-200;
}
.dropdown-panel-batches .p-dropdown-item:last-child {
  @apply border-b-0;
}
.dropdown-panel-batches .p-dropdown-header{
  @apply border-b border-gray-300;
}
.p-checkbox .p-checkbox-box {
  background-color: #e5e7eb; 
  border-color: #e5e7eb; 
}

.p-checkbox .p-checkbox-box:checked {
  background-color: #1fc95e;
  border-color: #1fc95e;
}

.p-checkbox .p-checkbox-icon {
  color: #ffffff; 
}

.p-checkbox .p-checkbox-box:hover {
  border-color: #9ca3af;
}

.p-checkbox input:checked ~ .p-checkbox-box {
  background-color: #1fc95e; 
  border-color: #1fc95e; 
}
